.FAndM {
  width: 100%;

  //////////////////////////
  // HEADER
  &__header {
    width: 100%;
    height: 100vh;

    background-image: url(https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto:best/v1601565438/kjmtrading/farming-and-manufacturing/F_M_hero_ibghsb.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      max-width: 100rem;
      margin: 0 auto;
      font-size: 5rem;
      font-size: 400;
      font-family: $font-title;
      color: $color-white;
      padding: 3rem;
      text-align: center;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }

  /////////////////////////
  // CONTENT
  &__content {
    width: 100%;

    &__inner {
      max-width: 100rem;
      margin: 0 auto;
      padding: 10rem 3rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__title {
      flex: 0 0 10%;
      align-self: start;
      font-size: 3rem;

      @include respond(phone) {
        margin-bottom: 2rem;
        font-size: 2.4rem;
      }
    }

    &__divider {
      width: 20rem;
      height: 1px;
      background-color: #404040;
      margin-top: 3rem;

      flex: 0 0 10%;
      align-self: start;

      @include respond(phone) {
        display: none;
      }
    }

    &__description {
      flex: 0 0 50%;
      font-size: 1.6rem;

      & p:not(:last-of-type) {
        margin-bottom: 1.5rem;
      }
    }
  }

  ////////////////////////
  // GALLERY
  &__gallery {
    width: 100%;

    &__inner {
      max-width: 90rem;
      margin: 0 auto;
      padding: 10rem 3rem;
    }

    &__title {
      font-size: 3rem;
    }

        &__images {
      padding: 0 3rem;
      max-width: 120rem;
      margin: auto;

      & img {
        display: inline-block;
        max-width: 30rem;
        object-fit: cover;

        &:not(:last-of-type) {
          margin-right: 1.5rem;
        }

        @include respond(big-desktop) {
          max-width: 25rem;
        }
      }
    }
  }

  &__statement {
    width: 100%;
    margin-top: 15rem;
    margin-bottom: 15rem;
    background-color: #eee;

    &__inner {
      max-width: 120rem;
      padding: 0 3rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 33.75em) {
        padding: 3rem;
        flex-direction: column;
        align-items: center;
      }

      @media only screen and (max-width: 23.13em) {
        padding: 5rem 3rem;
      }
    }

    &__content {
      flex: 0 0 40%;

      @media only screen and (max-width: 23.13em) {
        flex: 0 0 100%
      }

      & h2 {
        font-size: 3rem;
        font-family: $font-title;
        margin-bottom: 3rem;

        @media only screen and (max-width: 33.75em) {
          font-size: 2.4rem;
          margin-bottom: 1.5rem;
          text-align: center;
          max-width: 35rem;
          margin-left: auto;
          margin-right: auto;
        }

        @media only screen and (max-width: 23.13em) {
          font-size: 2rem;
          text-align: center;
        }
      }

      & p {
        font-size: 1.8rem;

        @media only screen and (max-width: 37.5em) {
          text-align: center;
          max-width: 30rem;
          margin: 0 auto;
          font-size: 1.6rem;
        }

        // @media only screen and (max-width: 33.75em) {
        //   font-size: 1.6rem;
        // }
      }
    }

    &__image-box {
      flex: 0 0 40%;
      transform: translateY(4px);

      @media only screen and (max-width: 37.5em) {
        display: none;
      }
    }

    &__image {
      width: 100%;
      height: auto;
    }
  }
}

// .FAndM {
//   width: 100%;
//   margin-top: 80px;

//   &__header {
//     background: url("https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto:best/v1601565438/kjmtrading/farming-and-manufacturing/F_M_hero_ibghsb.jpg");
//     background-repeat: no-repeat;
//     background-size: cover;
//     width: 100%;
//     height: 700px;

//     &__inner {
//       max-width: 1200px;
//       margin: auto;
//       display: flex;
//       height: 700px;
//       flex-direction: column;
//       justify-content: center;

//       & h1 {
//         color: #fff;
//         max-width: 1000px;
//         margin: auto;
//         font-size: 50px;
//         font-family: redrose;
//         text-align: center;
//       }
//     }
//   }

//   &__content {
//     &__inner {
//       max-width: 1200px;
//       padding: 64px 32px;
//       margin: auto;
//       display: flex;
//       justify-content: space-between;
//     }

//     &__title {
//       & h2 {
//         font-size: 50px;
//         margin-bottom: 2rem;
//         font-family: redrose;
//       }
//     }

//     &__divider {
//       width: 200px;
//       height: 1px;
//       background-color: #404040;
//       margin-top: 45px;
//       margin-left: 10px;
//       margin-right: 10px;
//     }

//     &__description {
//       max-width: 500px;
//       padding: 0 32px;
//       & p {
//         margin-bottom: 1.9rem;
//         font-size: 18px;
//         line-height: 2rem;
//       }
//     }
//   }

//   &__gallery {
//     width: 100%;

//     &__inner {
//       max-width: 1200px;
//       margin: auto;
//       padding: 64px 32px;
//     }

//     &__title {
//       max-width: 800px;
//       padding: 32px;
//       margin: auto;
//       font-size: 28px;
//       font-family: redrose;
//       margin-bottom: 3rem;
//       text-align: center;
//     }

//     /* Images */
//     &__images {
//       padding: 0;
//       max-width: 1200px;
//       margin: auto;

//       & img {
//         display: inline-block;
//         width: 350px;
//         height: 500px;
//         margin-bottom: 3rem;
//         margin-right: 15px;
//         object-fit: cover;
//       }
//     }
//   }

//   /* Statement */
//   &__statement {
//     width: 100%;
//     background: #c1bec2;
//     margin-top: 150px;
//     margin-bottom: 150px;

//     &__inner {
//       max-width: 1200px;
//       height: 100%;
//       margin: auto;
//       padding: 0px 32px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//     }

//     &__content {
//       max-width: 500px;
//       align-self: center;

//       & h2 {
//         font-size: 40px;
//         margin-bottom: 1rem;
//       }

//       & p {
//         font-size: 20px;
//       }
//     }

//     &__image {

//       & img {
//         display: inline-block;
//         width: 500px;
//         height: auto;
//         object-fit: cover;
//       }
//     }
//   }
// }

// @media (max-width: $breakpoint-small-screens) {
//   .FAndM {
//     &__header {
//       &__inner {
//         & h1 {
//           font-size: 32px;
//         }
//       }
//     }

//     &__content {
//       &__inner {
//         flex-direction: column;
//       }

//       &__title {
//         & h2 {
//           font-size: 32px;
//         }
//       }

//       &__divider {
//         display: none;
//       }

//       &__description {
//         padding: 0;
//       }
//     }

//     &__gallery {
//       height: 700px;

//       &__inner {
//         padding: 32px;
//       }

//       &__title {
//         padding: 0;
//         text-align: center;
//         font-size: 22px;
//       }

//       &__images {

//         & img {
//           width: 100%;
//           height: 390px;
//           object-fit: cover;
//         }
//       }
//     }

//     &__statement {
//       margin: 50px 0px;
//       &__inner {
//         height: 100%;
//         padding-top: 50px;
//         padding-bottom: 50px;
//       }

//       &__content {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;

//         & h2 {
//           font-size: 28px;
//         }

//       }
//       &__image {
//         display: none;
//       }
//     }
//   }
// }
