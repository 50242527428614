.OilAndGas {
  width: 100%;

  &__header {
    width: 100%;
    height: 100vh;

    background-image: url("../../assets/oil-field.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      max-width: 100rem;
      margin: 0 auto;
      font-size: 5rem;
      font-weight: 400;
      font-family: $font-title;
      color: $color-white;
      padding: 3rem;
      text-align: center;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }

  &__content {
    width: 100%;

    &__inner {
      max-width: 100rem;
      margin: 0 auto;
      padding: 10rem 3rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__title {
      flex: 0 0 20%;
      align-self: start;
      font-size: 2rem;

      @include respond(phone) {
        margin-bottom: 2rem;
        font-size: 2.4rem;
      }
    }

    &__divider {
      width: 20rem;
      height: 1px;
      background-color: #404040;
      margin-top: 3rem;

      flex: 0 0 10%;
      align-self: start;

      @include respond(phone) {
        display: none;
      }
    }

    &__description {
      flex: 0 0 50%;
      font-size: 1.6rem;

      & p:not(:last-of-type) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__what-we-do {
    width: 100%;

    &__inner {
      max-width: 120rem;
      margin: 0 auto;
      padding: 10rem 3rem 10rem 3rem;
    }

    &__title {
      font-size: 3rem;
      font-family: $font-title;
      text-align: center;
      margin-bottom: 1.6rem;
      @include respond(phone) {
      }
    }

    &__subtitle {
      font-size: 1.6rem;
      text-align: center;
      margin-bottom: 3.2rem;
    }

    &__lists {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 3.2rem;
    }

    &__list-item {
      background-color: #630074;
      color: white;
      padding: 1.6rem;
      width: 33%;
      margin-bottom: 12px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      white-space: nowrap;

      @include respond(phone) {
        width: 100%;
      }
    }

    &__pdf {
      display: flex;
      justify-content: center;

      & a {
        text-decoration: none;
        background: #fff;
        border: 1px solid #630074;
        text-align: center;
        font-size: 18px;
        padding: 10px 16px;
				transition: 200ms;

        &:hover {
          background-color: #630074;
          color: #fff;
        }
      }
    }
  }
}
