.Home {
  width: 100%;

  //////////////////////////
  // HEADER
  &__header {
    width: 100%;
    height: 100vh;
    color: $color-white;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include respond(phone) {
      background-image: url(https://res.cloudinary.com/xoladigital/image/upload/c_mfit,h_635,q_auto:low,w_681/v1601661778/kjmtrading/homepage/landing_page_image_hcz5zo.jpg);
      background-size: cover;
      background-position: 60% 0;
      object-fit: cover;
      background-repeat: no-repeat;
    }
  }

  &__header-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 8;

    @include respond(phone) {
      display: none;
    }
  }

  &__header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 9;
  }

  &__heading-box {
    max-width: 100%;
    position: relative;
    padding-left: 15%;
    z-index: 16;

    @include respond(big-desktop) {
      padding-left: 25%;
    }

    @include respond(phone) {
      padding-left: 10%;
    }
  }

  &__heading {
    font-size: 5rem;
    font-family: $font-title;
    font-weight: 400;

    @include respond(phone) {
      font-size: 3rem;
      padding: 0 2rem;
    }
  }

  ////////////////////////
  // OVERVIEW
  &__overview {
    width: 100%;

    &__inner {
      max-width: 100rem;
      margin: 0 auto;
      padding: 5rem 3rem;
    }

    &__items {
      list-style: none;

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    &__item {
      font-size: 2rem;
      font-family: $font-title;
      font-weight: bold;

      @include respond(phone) {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        flex: 0 0 50%;
      }
    }
  }

  ////////////////////////
  // ABOUT
  &__about {
    width: 100%;

    &__inner {
      max-width: 100rem;
      margin: 0 auto;
      padding: 2rem 3rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(phone) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &__image-box {
      flex: 0 0 45%;

      @include respond(phone) {
        flex: 0 0 100%;
        margin-bottom: 2rem;
      }
    }

    &__image {
      width: 100%;
      height: auto;
      object-fit: cover;

      @include respond(phone) {
        width: 100%;
        height: 35rem;
        object-fit: cover;
      }
    }

    &__content {
      flex: 0 0 45%;
      font-size: 1.8rem;
      line-height: 1.7;

      & p:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  ////////////////////////
  // SERVICES
  &__services {
    width: 100%;

    &__inner {
      max-width: 120rem;
      margin: 0 auto;
      padding: 10rem 3rem 5rem 3rem;
    }

    &__title {
      font-size: 2rem;
      font-family: $font-title;
      margin-bottom: 2rem;
    }

    &__categories {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__category {
      display: block;
      flex: 0 0 30%;

      @include respond(phone) {
        flex: 0 0 100%;
        margin-bottom: 1rem;
        height: 450rem;
      }
    }

    &__category-item {
      position: relative;

      & img {
        display: inline-block;
        width: 100%;
        object-fit: cover;
      }

      & h3 {
        position: absolute;
        bottom: 5rem;
        left: 2rem;
        right: 2rem;
        font-size: 2rem;
        padding-top: 1.5rem;
        font-family: $font-title;
        letter-spacing: 1px;
        color: $color-white;
        text-decoration: none;
        border-top: 3px solid white;

        @include respond(tablet-port) {
          font-size: 1.4rem;
          padding-top: 1.2rem;
          bottom: 3rem;
        }

        @include respond(phone) {
          font-size: 1.6rem;
          bottom: 3rem;
        }
      }
    }
  }

  ////////////////////////
  // MANTRA
  &__mantra {
    width: 100%;
    height: 100%;
    // padding: 10rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
      flex-direction: column;
      padding: 0 3rem 8rem 3rem;
    }

    &__image {
      width: 100%;
      height: 60rem;
      background-image: url("https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto:best/v1601456004/kjmtrading/homepage/cocoa-farmer_jzglgz.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;

      flex: 0 0 50%;

      @include respond(phone) {
        display: none;
      }
    }

    &__content-box {
      width: 100%;
      height: 100%;
      z-index: 100;
      position: relative;
      // background: #eee;

      display: flex;
      flex-direction: column;
      justify-content: center;

      flex: 0 0 50%;
    }
    &__content {
      display: inline-block;
      margin-left: auto;
      padding-left: 3rem;

      @include respond(phone) {
        margin-left: 0;
        padding-left: 0;
      }

      & h2 {
        display: inline-block;
        font-size: 6rem;
        font-family: $font-title;
        line-height: 1.2;
        font-weight: 400;
        color: $color-primary;
        margin-bottom: 2rem;
        @include respond(phone) {
          font-size: 4rem;
        }
      }
    }
  }

  ////////////////////////
  // DIVIDER
  &__divider {
    &::after {
      content: "";
      display: block;
      height: 15rem;
      width: 1px;
      margin: 0 auto;
      background: #6b6b6b;

      @include respond(phone) {
        height: 6rem;
      }
    }
  }

  ////////////////////////
  // CONTACT-FORM
  &__contact {
    width: 100%;

    &__inner {
      max-width: 90rem;
      margin: 6rem auto;
      padding: 3rem;
      background-color: $color-primary-light;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__form {
      flex: 0 0 45%;
      align-self: start;

      @include respond(phone) {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 3rem;
      }

      &__inputGroup {
        margin-bottom: 2rem;

        & input {
          width: 100%;
          border: none;
          background-color: transparent;
          border-bottom: 1px solid #585858;
          padding: 1rem;
          font-size: 1.4rem;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: #5e5e5e;
            font-size: 1.4rem;
          }
        }
      }

      &__btn {
        & button {
          outline: none;
          border: none;
          background-color: $color-primary;
          color: $color-white;
          padding: 1rem 2rem;
          cursor: pointer;
        }
      }
    }

    &__fieldset {
      outline: none;
      border: none;
      margin-bottom: 3rem;

      & h2 {
        font-size: 3rem;
        font-family: $font-title;
      }

      & p {
        font-size: 1.6rem;
      }
    }

    &__message {
      background-color: #fff;
      font-size: 1.5rem;
      padding: 0.5rem 3rem;
      border-radius: 0.8rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      margin-bottom: 2rem;

      &--success {
        color: green;
      }

      &--fail {
        color: green;
      }
    }

    &__address {
      flex: 0 0 45%;
      background-image: url(https://res.cloudinary.com/xoladigital/image/upload/c_scale,f_auto,q_auto:low,w_500/v1602160967/kjmtrading/Logo/address_zvhcnp.png);
      background-size: cover;
      background-repeat: no-repeat;

      @include respond(phone) {
        flex: 0 0 100%;
        width: 100%;
      }

      &__inner {
        padding: 5rem 3rem;
      }

      &__content {
        background-color: $color-white;
        padding: 3rem;
        border-radius: 4px;
      }
    }

    &__infoItem {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      display: flex;
      align-items: center;
    }

    &__info {
      flex: 0 0 auto;
      align-self: start;

      & h3 {
        font-size: 1.6rem;
      }

      & p {
        font-size: 1.4rem;
      }

      & p.highlight {
        color: $color-primary;
      }
    }

    &__info-icon {
      flex: 0 0 20%;
      align-self: start;
      padding-top: 0.6rem;
    }
  }
}
