/* BREAKPOINTS */
$breakpoint-tiny-screens: 375px;
$breakpoint-small-screens: 450px;
$breakpoint-tablet: 600px;
$breakpoint-desktop: 1000px;
$breakpoint-bs-sm: 576px;
$breakpoint-bs-md: 768px;
$breakpoint-bs-lg: 992px;
$breakpoint-bs-xl: 1200px;

$bp-phone: 37.5em; // 600px; (600 / 16 * 100) = 37.5em;
$bp-tablet-portrait: 56.25em; // 900px; (900 / 16 * 100) = 56.25em;
$bp-tablet-landscape: 75em; // 1200px (1200 / 16 * 100) = 75em;
$bp-big-desktop: 112.5em; // 1800px (1800 / 16 * 100) = 112.5em;

/* COLORS */
$color-primary: #630074;
$color-primary-light: #f3adff;
$color-card-bg: #FEFBFF;
$color-body-text: #404040;
$color-white: #fff;

/* FONTS */
$font-default: futura;
$font-title: redrose;
