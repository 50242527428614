.Logo {
  width: 100%;
  margin-bottom: 8rem;

  &__image-box {
    width: 15rem;
    height: 5rem;
    margin: 0 auto;
  }

  &__image {
    width: 100%;
    height: auto;
  }
}