// MEDIA QUERY MANAGER
/*
0 - 600px phone
600 - 900 Tablet Portrait
900 - 1200 Tablet Landscape
1200 - 1800 is where our normal styles apply
1800 - Big Desktop

ORDER: Base + typography > general layouts[header, footer, etc] + grid > page layout > components

*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: $bp-phone) {
      @content;
    } // 600px
  }

  @if $breakpoint == tablet-port {
    @media (max-width: $bp-tablet-portrait) {
      @content;
    } // 900px
  }

  @if $breakpoint == tablet-land {
    @media (max-width: $bp-tablet-landscape) {
      @content;
    } // 1200px
  }

  @if $breakpoint == big-desktop {
    @media (min-width: $bp-big-desktop) {
      @content;
    } // 1800px
  }
}
