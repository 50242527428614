.Products {
  width: 100%;

  ////////////////////////
  // HEADER
  &__header {
    width: 100vw;
    height: 100vh;
    background-image: url(https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto:best/v1601456103/kjmtrading/productpage/product_page_hero_image_jiuaai.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      font-size: 5rem;
      font-weight: 400;
      font-family: $font-title;
      padding: 0 3rem;
      color: $color-white;
      text-align: center;

      @include respond(phone) {
        font-size: 3rem;
        font-weight: bold;
      }
    }
  }

  ////////////////////////
  // PRODUCT LISTS
  &__lists {
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba($color-primary-light, 0.8),
      rgba($color-white, 0.8)
    );

    &__inner {
      max-width: 100rem;
      margin: 0 auto;
      padding: 10rem 3rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__header {
      flex: 0 0 10%;
      align-self: start;
    }

    &__title {
      font-size: 3rem;

      @include respond(phone) {
        margin-bottom: 2rem;
      }
    }

    &__divider {
      width: 20rem;
      height: 1px;
      background-color: #404040;
      margin-top: 3rem;

      flex: 0 0 10%;
      align-self: start;

      @include respond(phone) {
        display: none;
      }
    }

    &__content {
      flex: 0 0 50%;

      font-size: 1.6rem;

      & p {
        margin-bottom: 2rem;
      }

      & ul {
        list-style-type: none;

        display: grid;
        grid-template-columns: repeat(2, 1fr);

        & li::before {
          content: "";
          background-color: #650083;
          border-radius: 50%;
          font-weight: bold;
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.8rem;
        }

        & li {
          font-size: 1.4rem;
          margin-bottom: 1.4rem;
          color: #404040;
        }
      }
    }
  }

  ////////////////////////
  // PRODUCT DETAILS
  &__details {
    width: 100%;

    &__inner {
      max-width: 120rem;
      margin: 0 auto;
      padding: 10rem 3rem 0 3rem;
    }

    &__title {
      font-size: 3rem;
      font-family: $font-title;
      text-align: center;
      margin-bottom: 15rem;

      @include respond(phone) {
        margin-bottom: 13rem;
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  ////////////////////////
  // PRODUCT DETAIL
  &__detail {
    flex: 0 0 30%;
    padding: 3rem;
    position: relative;

    @include respond(phone) {
      flex: 0 0 70%;
      margin: 0 auto 15rem auto;
    }

    @media only screen and (max-width: 33.7em) {
      flex: 0 0 100%;
    }

    height: 100%;
    min-height: 42rem;
    background-color: rgba($color-card-bg, 0.19);
    box-shadow: 1px 8px 20px rgba(130, 0, 136, 0.19);
    margin-bottom: 15rem;
    border-radius: 8px;

    &__title {
      font-size: 2rem;
      font-family: $font-title;
      margin-bottom: 2rem;
      margin-top: 10rem;
    }

    &__content {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    &__list {
      list-style: none;

      & li {
        font-size: 1.2rem;

        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }

        &::before {
          content: "";
          background-color: #650083;
          border-radius: 50%;
          font-weight: bold;
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.8rem;
        }
      }
    }
  }

  &__detail-image-box {
    width: 15rem;
    height: 15rem;

    position: absolute;
    top: -6rem;
    left: 50%;
    transform: translate(-50%, -0%);
  }

  &__detail-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  ////////////////////////
  // SERVICES
  &__services {
    width: 100%;
    height: 30rem;
    margin-bottom: 10rem;
    background-image: url(https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto:low/v1601456103/kjmtrading/productpage/product_page_parallax_xbkat6.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @include respond(phone) {
      background-attachment: scroll;
    }

    &__inner {
      max-width: 80rem;
      height: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 0 3rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      color: $color-white;
      font-size: 3rem;
      font-weight: 300;

      @include respond(phone) {
        font-size: 2rem;
      }
    }

    &__link:link,
    &__link:visited {
      font-size: 3rem;
      color: $color-primary-light;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}