.Footer {
  width: 100%;
  background-color: $color-primary;
  color: $color-white;
  font-size: 1.8rem;

  @include respond(phone) {
    font-size: 1.4rem;
  }

  &__inner {
    max-width: 120rem;
    margin: 0 auto;
    padding: 8rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(phone) {
      flex-wrap: wrap;
      padding: 6rem 3rem;
    }
  }

  &__links,
  &__info,
  &__designedBy {
    flex: 0 0 30%;
  }

  &__links {
    display: flex;
    flex-direction: column;
    :not(:last-child) {
      margin-bottom: 1.5rem;
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: $color-white;
    }
  }

  &__info {
    align-self: flex-end;
  }

  &__designedBy {
    align-self: flex-end;

    @include respond(phone) {
      flex: 0 0 100%;
      text-align: center;
      margin-top: 4rem;
    }

    a {
      display: inline-block;
      color: $color-white;
    }
  }

  &__image-box {
    width: 10rem;
    height: 5rem;

    @include respond(phone) {
      width: 7rem;
      height: 4rem;
      margin: auto;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }
}