.IntroOverlay {
  &__item {
    position: absolute;
    background-color: $color-primary;
    width: 33.333vw;
    height: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 14;

    @include respond(phone) {
      width: 100vw;
    }

    &:nth-child(2) {
      left: 33.333%;

      @include respond(phone) {
        display: none;
      }
    }

    &:nth-child(3) {
      left: 66.666%;

      @include respond(phone) {
        display: none;
      }
    }
  }
}
