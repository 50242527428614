*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px (10px/16px * 100)

  @include respond(tablet-land) {
    font-size: 56.25%;
  }

  @include respond(tablet-port) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  visibility: hidden;
}
