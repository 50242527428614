.Slider {
  max-width: 50rem;
  

  & h3 {
    font-size: 2.8rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  & p {
    font-size: 1.6rem;
    min-height: 10rem;
  }

  &__buttons {
    display: flex;
    
    & span {
      display: block;
      width: 20px;
      height: 20px;
      margin: 3px 3px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        background: #d4d4d4;
        border: 1px solid #cf10ff;
        border-radius: 50%;
        transition: background-color 0.3s ease;
        margin: 50% auto;
      }
    }

    & span.active {
      &::before {
        background: #cf10ff;
      }
    }
  }
}