.Loader {
  width: 100%;
  height: 100vh;
  &__line {
    width: 100%;
    height: 2px;
    background: #630074;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    animation: loaderAnimation 2.5s infinite 0s linear;
    animation-fill-mode: forwards;
    transition: all 0.15s ease-in-out;
  }

  &__text {
    
      color: #5d5d5d;
      position: absolute;
      top: 50%;
      left: 50%;
      translate: (-50%, -50%);
  }
}

@keyframes loaderAnimation {
  0% {
    width: 0;
  }
  25% {
    width: 12.5%;
  }
  50% {
    width: 35%;
  }
  75% {
    width: 57.5%;
  }
  100% {
    width: 79%;
  }
}
