.Services {
  width: 100%;

  ////////////////////////
  // HEADER
  &__header {
    width: 100%;
    height: 95vh;
    margin-top: 8rem;

    background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.4)),
      url(https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto/v1601456032/kjmtrading/servicepage/service_hero_image_1x_izz9eu.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      font-size: 5rem;
      font-family: $font-title;
      font-weight: 400;
      color: $color-white;
      text-align: center;
      padding: 0 3rem;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }

  ////////////////////////
  // PRODUCT LISTS
  &__lists {
    width: 100%;

    &__inner {
      max-width: 100rem;
      margin: 0 auto;
      padding: 10rem 3rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__header {
      flex: 0 0 10%;
      align-self: start;
    }

    &__title {
      font-size: 3rem;

      @include respond(phone) {
        margin-bottom: 2rem;
      }
    }

    &__divider {
      width: 20rem;
      height: 1px;
      background-color: #404040;
      margin-top: 3rem;

      flex: 0 0 10%;
      align-self: start;

      @include respond(phone) {
        display: none;
      }
    }

    &__content {
      flex: 0 0 50%;

      font-size: 1.6rem;

      & p {
        margin-bottom: 2rem;
      }

      & ul {
        list-style-type: none;

        display: grid;
        grid-template-columns: 1fr;

        & li::before {
          content: "";
          background-color: #650083;
          border-radius: 50%;
          font-weight: bold;
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.8rem;
        }

        & li {
          font-size: 1.4rem;
          margin-bottom: 1.4rem;
          color: #404040;
        }
      }
    }
  }

  ////////////////////////
  // SERVICE TYPES
  &__types {
    width: 100%;

    &__inner {
      max-width: 120rem;
      margin: 0 auto;
      padding: 0 3rem 10rem 3rem;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__service {
      flex: 0 0 30%;

      @include respond(phone) {
        flex: 0 0 100%;
        &:not(:last-child) {
          margin-bottom: 3rem;
        }
      }
    }

    &__service-image-box,
    &__reverseService-image-box {
      // max-width: 35rem;

      @include respond(phone) {
        max-width: 100%;
      }
    }

    &__service-image,
    &__reverseService-image {
      width: 100%;
    }

    &__service-title,
    &__reverseService-title {
      font-size: 1.4rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      font-family: $font-title;
    }

    &__service-content,
    &__reverseService-content {
      background-color: #eee;
      padding: 4rem 2rem;
      min-height: 25rem;

      & p {
        font-size: 1.4rem;
      }
    }

    &__service-content {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &__reverseService-content {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .show-on-mobile {
    display: none;

    @include respond(phone) {
      display: block;
    }
  }

  .hide-on-mobile {
    display: block;

    @include respond(phone) {
      display: none;
    }
  }

  ////////////////////////
  // STATEMENT
  &__statement {
    width: 100%;

    & h2 {
      max-width: 50rem;
      margin: 0 auto;
      font-size: 3rem;
      padding: 5rem 3rem 0rem 3rem;
      text-align: center;

      @include respond(phone) {
        padding: 2rem 3rem 7rem 3rem;
      }
    }
  }

  ////////////////////////
  // IMPORT
  &__import {
    width: 100%;

    &__inner {
      max-width: 120rem;
      margin: 0 auto;
      padding: 0 3rem;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__content {
      flex: 0 0 50%;

      @include respond(phone) {
        flex: 0 0 100%;
      }
    }

    &__content-title {
      font-size: 3rem;
      font-family: $font-title;
      font-weight: 400;
      text-align: center;
      margin-bottom: 3rem;
    }

    &__content-description {
      font-size: 1.6rem;
      margin-bottom: 1rem;

      & p:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    &__lists {
      font-size: 1.6rem;
      & ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        list-style: none;
        margin-bottom: 2rem;
        color: #555;

        & li {
          margin-bottom: 1rem;
        }
      }
    }

    &__image-box {
      flex: 0 0 35%;
      position: relative;

      @include respond(phone) {
        display: none;
      }
    }

    &__image {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    &__image-box::before {
      content: "";
      display: inline-block;
      width: 40rem;
      height: 40rem;
      background-image: url("https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto:low/v1601565500/kjmtrading/servicepage/service_grass_m7yn2a.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateX(-10rem) translateY(20rem);
      position: relative;
      z-index: -1;

      @include respond(tablet-port) {
        width: 30rem;
        height: 30rem;
      }

      @include respond(phone) {
        display: none;
      }
    }
  }
  //////////////////////////
  // GALLERY

  &__gallery {
    width: 100%;
    height: 30rem;
    margin-top: 10rem;
    margin-bottom: 10rem;
    background-image: url(https://res.cloudinary.com/xoladigital/image/upload/v1601565514/kjmtrading/servicepage/serviceparrallax_side_jzexrd.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @include respond(phone) {
      background-attachment: scroll;
      height: 20rem;
    }
  }
}

// .Services {
//   width: 100%;
//   margin-top: 80px;

//   &__header {
//     background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
//       url("https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto/v1601456032/kjmtrading/servicepage/service_hero_image_1x_izz9eu.jpg");
//     background-repeat: no-repeat;
//     width: 100%;
//     background-size: cover;
//     background-position: center center;
//     height: 866px;

//     &__inner {
//       max-width: 1200px;
//       height: 100%;
//       margin-left: auto;
//       margin-right: auto;
//       margin-bottom: 4rem;
//       display: flex;
//       justify-content: center;

//       & h1 {
//         color: #fff;
//         margin: auto;
//         font-size: 50px;
//         font-family: redrose;
//       }
//     }
//   }

//   &__lists {
//     width: 100%;
//     background: #fff;
//     color: #000;
//     transform: translateY(-4px);

//     &__inner {
//       max-width: 1200px;
//       height: 500px;
//       margin: auto;
//       padding: 128px 32px 0px 32px;
//       display: flex;
//       justify-content: space-between;
//     }

//     &__header {
//       & h2 {
//         font-size: 50px;
//         font-weight: 400;
//         font-family: redrose;
//       }
//     }

//     &__divider {
//       width: 200px;
//       height: 1px;
//       background-color: #404040;
//       margin-top: 45px;
//       margin-left: 10px;
//       margin-right: 10px;
//     }

//     &__content {
//       max-width: 500px;

//       & p {
//         font-size: 18px;
//         line-height: 1.5rem;
//         color: #404040;
//         margin-bottom: 1rem;
//       }

//       & ul {
//         list-style-type: none;

//         & li::before {
//           content: "";
//           background-color: #650083;
//           border-radius: 100%;
//           font-weight: bold;
//           display: inline-block;
//           width: 0.5rem;
//           height: 0.5rem;
//           margin-right: 0.75rem;
//         }

//         & li {
//           font-size: 1rem;
//           margin-bottom: 1rem;
//           color: #404040;
//         }
//       }
//     }
//   }

//   &__types {
//     width: 100%;

//     &__inner {
//       max-width: 1200px;
//       margin: auto;
//       padding: 0 32px;
//       display: flex;
//       justify-content: space-between;
//     }

//     &__service {
//       max-width: 350px;
//       background: #eee;
//       border-radius: 16px;

//       &__img {
//         margin-bottom: 2rem;
//         & img {
//           width: 100%;
//           object-fit: cover;
//         }
//       }

//       &__title {
//         text-transform: uppercase;
//         font-size: 20px;
//         font-family: redrose;
//         margin-bottom: 1rem;
//         padding: 0 2rem;
//       }

//       &__content {
//         color: #575757;
//         font-size: 1rem;
//         line-height: 1.6rem;
//         padding: 0 2rem 2rem 2rem;
//       }
//     }

//     &__reverseService {
//       max-width: 350px;
//       background: #eee;
//       border-radius: 16px;

//       &__img {
//         margin-bottom: -2px;
//         & img {
//           width: 100%;
//           object-fit: cover;
//         }
//       }

//       &__title {
//         text-transform: uppercase;
//         font-size: 20px;
//         font-family: redrose;
//         margin-top: 3.5rem;
//         margin-bottom: 1rem;
//         padding: 0 2rem;
//       }

//       &__content {
//         color: #575757;
//         font-size: 1rem;
//         line-height: 1.6rem;
//         padding: 0 2rem 4rem 2rem;
//       }
//     }
//   }

//   &__statement {
//     width: 100%;
//     padding: 200px 32px 100px 32px;
//     text-align: center;

//     & h2 {
//       font-size: 40px;
//       font-family: redrose;
//       max-width: 700px;
//       margin: auto;
//     }
//   }

//   &__import {
//     width: 100%;
//     margin-bottom: 20rem;

//     &__inner {
//       max-width: 1200px;
//       padding: 64px 0px;
//       margin: auto;
//       display: flex;
//       justify-content: space-between;
//     }

//     &__title {
//       width: 100%;
//       text-align: center;
//     }

//     &__content {
//       max-width: 600px;
//       padding: 0 32px;

//       &__title {
//         & h2 {
//           font-size: 3.75rem;
//           font-family: redrose;
//           font-weight: 400;
//         }
//         margin-bottom: 3rem;
//       }

//       &__description {
//         line-height: 2.188rem;
//         font-size: 1.125rem;
//         color: #5d5d5d;
//       }
//     }

//     &__lists {
//       line-height: 1.6rem;
//       margin-top: 1rem;
//       color: #5d5d5d;
//       font-size: 1.125rem;

//       & ul {
//         display: flex;
//         flex-direction: column;
//         flex-wrap: wrap;
//         max-height: 300px;
//         list-style-type: none;
//         margin-bottom: 1rem;

//         & li {
//           margin-bottom: 1rem;
//         }
//       }
//     }

//     &__img {
//       position: relative;
//       width: 450px;
//       align-self: center;
//       justify-self: flex-end;

//       & img {
//         position: absolute;
//         right: 0;
//         top: 150px;
//         width: 100%;
//         height: 600px;
//         display: block;
//       }
//     }

//     &__img::before {
//       content: "";
//       display: inline-block;
//       width: 450px;
//       height: 469px;
//       background: url("https://res.cloudinary.com/xoladigital/image/upload/f_auto,q_auto:low/v1601565500/kjmtrading/servicepage/service_grass_m7yn2a.jpg");
//       background-size: cover;
//       background-repeat: no-repeat;
//       transform: translateX(-100px);
//     }
//   }
//   &__gallery {
//     width: 100%;
//     margin-bottom: 150px;

//     & img {
//       display: block;
//       width: 100%;
//       height: auto;
//     }
//   }
// }

// @media (max-width: $breakpoint-small-screens) {
//   .Services {
//     &__header {
//       &__inner {
//         & h1 {
//           text-align: center;
//           font-size: 2rem;
//         }
//       }

//       &__content {
//         padding: 32px;
//         margin-left: 0;

//         & h2 {
//           font-size: 2rem;
//           text-align: center;
//         }
//       }
//     }

//     &__lists {
//       &__inner {
//         flex-direction: column;
//         padding: 4rem 2rem 0 2rem;
//         height: 100%;
//       }

//       &__header {
//         & h2 {
//           font-size: 2rem;
//           margin-bottom: 2rem;
//         }
//       }

//       &__divider {
//         display: none;
//       }
//     }

//     &__types {
//       &__inner {
//         padding: 2rem 2rem 0 2rem;
//         flex-direction: column;
//       }

//       &__service {
//         text-align: center;
//         padding: 0;
//         margin-bottom: 3rem;
//       }
//     }

//     &__statement {
//       padding: 2rem;

//       & h2 {
//         font-size: 2rem;
//         text-align: center;
//       }
//     }

//     &__import {
//       margin-bottom: 0;
//       &__inner {
//         flex-direction: column;
//         padding: 32px;
//       }

//       &__content {
//         margin: auto;
//         padding: 0;

//         &__title {
//           & h2 {
//             font-size: 3rem;
//           }
//         }
//       }

//       &__img {
//         display: none;
//       }

//       &__lists {
//         & ul {
//           max-width: 100%;
//           max-height: 100%;
//         }
//       }
//     }

//     &__gallery {
//       margin-bottom: 2rem;

//       & img {
//         height: 200px;
//         object-fit: cover;
//       }
//     }
//   }
// }
