@font-face {
    font-family: 'futura';
    src: url(fonts/futura-medium.ttf);
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'redrose';
    src: url(fonts/redrose-regular.ttf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'redrose';
    src: url(fonts/redrose-light.ttf);
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'redrose';
    src: url(fonts/redrose-bold.ttf);
    font-style: normal;
    font-weight: bold;
}

body {
    font-family: $font-default;
    font-weight: 400;
    line-height: 1.6;
    color: $color-body-text;
}   