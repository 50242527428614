.AppLayout {
  width: 100%;
  position: relative;

  &__header {
    width: 100%;
    height: 7rem;
    position: fixed;
    top: 0;
    background-color: $color-white;
    z-index: 1000;
  }

  &__body {
    position: relative;
  }
}

.AppLayout__header--no-background {
  background-color: transparent;
}

.AppLayout__header--active {
  background-color: $color-primary;
}

.Navigation {
  max-width: 120rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 3rem;
  font-size: 1.8rem;

  display: flex;
  align-items: center;

  &__logo-box {
    width: 10rem;
    height: 5rem;
  }

  &__logo {
    width: 100%;
    height: auto;
  }

  &__links {
    margin-left: auto;
    list-style: none;

    @include respond(phone) {
      display: none;
    }
  }

  &__link-item {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 3rem;
    }
  }

  &__link:link,
  &__link:visited {
    text-decoration: none;
    color: $color-primary;
    display: inline-block;
    transition: all 0.2s;

    &:hover {
      border-bottom: 1px solid $color-primary;
    }
  }

  &__menu-icon {
    display: none;

    @include respond(phone) {
      display: block;
      margin-left: auto;
    }
  }
}

.Navigation__links--no-background {
  .Navigation__link:link,
  .Navigation__link:visited {
    color: $color-white;

    &:hover {
      border-bottom: 1px solid $color-white;
    }
  }
}

@include respond(tablet-port) {
  .MobileNavigation {
    display: none;
  }
}

@include respond(phone) {
  .MobileNavigation {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    margin-top: 7rem;
    background: $color-primary;
    list-style: none;
    padding: 6rem 3rem;
    text-align: center;
    z-index: 3000 !important;

    @include respond(big-desktop) {
      display: none;
    }

    &__link-item {
      display: block;
      &:not(:last-of-type) {
        margin-bottom: 3rem;
      }
    }

    &__link:link, &__link:visited {
      display: inline-block;
      color: $color-white;
      text-decoration: none;
      font-size: 2rem;
    }
  }
}